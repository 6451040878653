import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ConfirmDialog from "@/view/pages/ConfirmDialog.vue";
import { mapGetters } from "vuex";
import SchedulerApiService from "@/core/services/api.service.scheduler";
import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service";
import MFCategoriesApiService from "@/core/services/api.service.mf-categories";

const TYPES = [
  {
    id: "MAIN_FEED",
    name: "Main Feed"
  },
  {
    id: "PREMIUM",
    name: "Premium"
  },
  {
    id: "LOCAL_DEALS",
    name: "Local Deals"
  }
];

export default {
  name: "mainFeedCategoriesAdd",
  computed: {
    ...mapGetters(["currentUser"]),
    name() {
      return this.$i18n.t("menu.mainFeedCategories");
    },
    selectLabel() {
      if (this.allCitiesSelected) {
        return "All cities selected";
      }
      return this.$t("commonTable.city");
    }
  },
  data() {
    return {
      categoryName: "",
      cityName: "",
      shopCategories: [],
      oobleeCategoriesSelected: [],
      totalShopCategories: 0,
      loading: true,
      dialogDelete: false,
      errors: [],
      form: {
        name: "",
        ord: null,
        cityId: null,
        image: "",
        countryId: null,
        categoryIds: [],
        shopIds: [],
        productIds: []
      },
      selectedShops: [],
      requiredRule: [v => !!v || "Field is required"],
      cities: [],
      citiesLoading: false,
      countries: [],
      citiesFilter: [],
      countriesFilter: [],
      selectedCountry: undefined,
      selectedCountryFilter: undefined,
      selectedOobleeCategory: undefined,
      shops: [],
      shopsToAdd: [],
      addShopsDisable: false,
      searchShops: "",
      shopsAreLoading: false,
      oobleeCategories: [],
      oobleeCategoriesLoading: false,
      uploadedImage: "",
      filterShops: false,
      filterItems: false,
      shopCity: "",
      userCities: [],
      citiesIsLoading: false,
      pagination: {},
      allShopsSelected: false,
      searchProductsTerm: "",
      products: [],
      loadingProducts: false,
      selectedProducts: [],
      allCitiesSelected: false,
      selectedCity: [],
      routeData: {},
      productTableHeaders: [
        {
          text: "",
          value: "delete",
          sortable: false,
          width: "3%"
        },
        {
          text: "ID",
          value: "productId",
          sortable: false,
          align: "left"
        },
        {
          text: "Item title",
          value: "name",
          sortable: false,
          align: "left"
        },
        {
          text: this.$i18n.t("commonTable.shopName"),
          value: "shopName",
          sortable: false,
          align: "left"
        }
      ],
      isEdit: false,
      isCopy: false,
      types: TYPES,
      selectedType: null,
      processProductsChange: this.debounce(() => this.retrieveProducts()),
    };
  },
  watch: {
    pagination: {
      handler() {
        this.retrieveShops();
        this.retrieveProducts();
      },
      deep: true
    }
  },
  created() {},
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: `Main Feed Categories > New category` }]);
    await SchedulerApiService.init();
    await ApiService.init();
    await MFCategoriesApiService.init();

    this.getCountries();
    this.routeData = this.$route.params?.categoryEdit || {};
    this.selectedCountry = this.routeData?.countryId || null;
    this.selectedCity = this.routeData?.cityId;
    this.getCities();
    this.getCitiesFilter();
    await this.retrieveProducts();
    await this.getOobleeCategories();
    await this.retrieveShops();

    this.isEdit = !!this.$route.params?.categoryEdit;
    this.isCopy = !!this.$route.params?.categoryCopy;

    if (this.isEdit) {
      this.routeData = this.$route.params?.categoryEdit || {};
      this.setForm(this.routeData);
    } else {
      this.routeData = this.$route.params?.categoryCopy || {};
      this.setForm(this.routeData);
      // await this.getSingleCategory(routeData.id);
    }
  },
  components: {
    ConfirmDialog
  },
  methods: {
    getSingleCategory(id) {
      MFCategoriesApiService.getMFCategory(id).then(res => {
        this.setForm(res.data);
      });
    },
    filterCountry(country) {
      return this.countries.find(c => c.id === country?.id);
    },
    setForm(data) {
      this.form = {
        name: data?.name || "",
        ord: data?.ord || null,
        // cityIds: data.cityIds ? data.cityIds.split(",").map(id => parseInt(id)) : null,
        cityId: data?.cityId || null,
        image: data?.image || "",
        countryId: data?.countryId || null,
        categoryIds: data?.categoryIds ? data.categoryIds.split(",").map(id => parseInt(id)) : null,
        shopIds: data?.shopIds ? data.shopIds.split(",").map(id => parseInt(id)) : null,
        productIds: data?.productIds ? data.productIds.split(",").map(id => parseInt(id)) : null
      };

      this.selectedType = TYPES.find(item => item.id === data?.type) || null;

      if (this.isEdit) {
        this.form.id = data?.id;
      }
      this.uploadedImage = data?.image;
      if (this.uploadedImage) {
        this.$refs.imageContainer.style.backgroundImage = `url(${this.uploadedImage})`;
      }
      this.selectedCountry = data?.countryId || null;
      /*     if (data?.categoryIds) {
        this.oobleeCategoriesSelected = data.categoryIds.split(",").map(id => {
          const category = this.oobleeCategories.find(category => category.id === parseInt(id));
          return { id: parseInt(id), title: category ? category.title : "" };
        });
      } else {
        this.oobleeCategoriesSelected = [];
      }*/
      this.selectedCity = this.form.cityId;
      /*      if (data?.shopIds) {
        this.selectedShops = data.shopIds.split(",").map(id => {
          const shop = this.shops.find(shop => shop.id === parseInt(id));
          console.log("shop", shop);
          return { id: parseInt(id), name: shop ? shop.name : "" };
        });
        // console.log("selektovanisopovi", this.selectedShops);
      } else {
        this.selectedShops = [];
      }*/
      if (data?.productIds) {
        this.selectedProducts = data.productIds.split(",").map(id => {
          const item = this.products.find(item => item.id === parseInt(id));
          return { id: parseInt(id), name: item ? item.name : "", shopName: item.shopName };
        });
      } else {
        this.selectedProducts = [];
      }
      this.getCities();
    },
    getCountries() {
      this.countriesLoading = true;
      return SchedulerApiService.getCountries()
        .then(res => {
          this.countries = res.data || [];
          this.countriesFilter = res.data || [];
        })
        .finally(() => {
          this.countriesLoading = false;
        });
    },
    onCountryChange() {
      this.getCities();
      this.retrieveShops();
      this.getOobleeCategories();
      this.retrieveProducts();
    },
    onCityChange() {
      this.getOobleeCategories();
    },
    onCountryChangeFilter() {
      this.getCitiesFilter();
      this.retrieveShops();
    },
    handleSelectAllCities(event) {
      if (event) {
        const ids = this.cities.map(item => item.id) || [];
        this.selectedCity = ids;
        this.allCitiesSelected = true;
        this.getOobleeCategories();
      } else {
        this.selectedCity = [];
        this.allCitiesSelected = false;
      }
    },
    getCities() {
      if (!this.selectedCountry) return;
      this.loading = true;

      return SchedulerApiService.getCities({
        countryId: this.selectedCountry?.id ? this.selectedCountry.id : this.selectedCountry
      })
        .then(res => {
          this.cities = res.data || [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCitiesFilter() {
      if (!this.selectedCountry) return;
      if (!this.selectedCountryFilter?.id) return;
      this.loading = true;

      return SchedulerApiService.getCities({
        countryId: this.selectedCountryFilter?.id
      })
        .then(res => {
          this.citiesFilter = res.data || [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    retrieveShops() {
      //  if (!this.selectedCountry) return;
      if (this.shopsAreLoading) return;

      const countryId = this.selectedCountryFilter?.id
        ? this.selectedCountryFilter?.id
        : this.selectedCountry?.id;
      const cityIds = this.shopCity;
      const params = {
        countryId: countryId || null,
        cityIds: cityIds || null
      };
      this.shopsAreLoading = true;
      ApiService.query("api/shops/dropdown-list", { params })
        .then(res => {
          this.shops = res.data;

          if (this.isEdit || this.isCopy) {
            this.selectedShops = this.routeData.shopIds.split(",").map(id => {
              const shop = this.shops.find(shop => shop?.id === parseInt(id));
              return { id: parseInt(id), name: shop ? shop.name : "" };
            });
          }
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.shopsAreLoading = false));
    },
    async retrieveProducts() {
      let params = {};

      params["name"] = this.searchProductsTerm;
      params["page"] = this.pagination.page;
      params["size"] = 10000;
      params["countryId"] = this.selectedCountry?.id;

      this.loadingProducts = true;
      await MFCategoriesApiService.getProducts(params)
        .then(res => {
          this.products = res?.data?.content;
          // console.log("produkti", this.products);
        })
        .finally(() => {
          this.loadingProducts = false;
        });
    },
    debounce(func, timeout = 400) {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    },
    handleSelectAllShops(event) {
      if (event) {
        this.form.shopIds = this.shops.map(item => item.id) || [];
        this.selectedShops = this.shops.slice();
      } else {
        this.form.shopIds = [];
        this.selectedShops = [];
      }
    },
    removeShop(shopId) {
      const shopIndex = this.selectedShops.findIndex(shop => shop.id === shopId);
      if (shopIndex !== -1) {
        this.selectedShops.splice(shopIndex, 1);
        this.form.shopIds = this.selectedShops.map(item => item.id) || [];
      }
    },
    removeCategory(categoryId) {
      const categoryIndex = this.oobleeCategoriesSelected.findIndex(
        category => category.id === categoryId
      );
      if (categoryIndex !== -1) {
        this.oobleeCategoriesSelected.splice(categoryIndex, 1);
        this.form.categoryIds = this.oobleeCategoriesSelected.map(item => item.id) || [];
      }
    },
    getOobleeCategories() {
      if (!this.selectedCountry) return;
      this.oobleeCategoriesLoading = true;
      const params = {};
      params["cityId"] =
        this.allCitiesSelected === true ? "0" : this.selectedCity ? this.selectedCity : "0";
      params["countryId"] = this.selectedCountry?.id ? this.selectedCountry?.id : "";
      params["lang"] = i18nService.getActiveLanguage();
      SchedulerApiService.query(params)
        .then(res => {
          const mergedCategories = [];
          res.data.forEach(category => {
            const categoryId = category.id;
            const categoryTitle = category.title;

            mergedCategories.push({ id: categoryId, title: categoryTitle });

            category.subcategories.forEach(subcategory => {
              const subcategoryId = subcategory.id;
              const subcategoryTitle = subcategory.title;

              mergedCategories.push({ id: subcategoryId, title: subcategoryTitle });
            });
          });

          this.oobleeCategories = mergedCategories;
          if (this.isEdit || this.isCopy) {
            this.oobleeCategoriesSelected = this.routeData.categoryIds.split(",").map(id => {
              const category = this.oobleeCategories.find(category => category.id === parseInt(id));
              return { id: parseInt(id), title: category ? category.title : "" };
            });
          }
        })
        .finally(() => {
          this.oobleeCategoriesLoading = false;
        });
    },
    async updatePhoto() {
      const fileInput = this.$refs.fileInput.$el.querySelector("input[type=file]");
      const image = fileInput.files[0];

      if (!this.uploadedImage) {
        if (!image) return;
        try {
          const response = await SchedulerApiService.uploadImage(image);
          this.uploadedImage = response.data;
          this.form.image = this.uploadedImage;
        } catch (error) {
          this.$log.error("Error: ", error);
          this.errored = true;
        }

        const file = fileInput.files[0];

        const reader = new FileReader();
        reader.onload = () => {
          this.selectedImage = reader.result;

          // postavljanje slike na bg
          this.$refs.imageContainer.style.backgroundImage = `url(${this.selectedImage})`;
        };

        reader.readAsDataURL(file);
      } else {
        this.onRemoveImage();
      }
    },
    onRemoveImage() {
      this.uploadedImage = "";
      this.selectedImage = null;
      this.form.image = "";
      this.$refs.imageContainer.style.backgroundImage = "none";
    },
    showFilter() {
      this.filterShops = !this.filterShops;
      if (this.selectedCountryFilter) {
        this.selectedCountryFilter = "";
      }
    },
    previousState() {
      this.$router.go(-1);
    },
    async delProduct(item) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to remove product " + item.name + " ?"
        )
      ) {
        this.deleteProductFromList(item);
      }
    },
    deleteProductFromList(item) {
      this.$log.debug("Shop to be removed: " + item);
      const newArray = this.selectedProducts.filter(obj => item.id !== obj.id);
      this.selectedProducts = newArray;
      this.$log.debug("new array: " + newArray);
    },
    async validateAndSave() {
      if (this.$refs.form.validate()) {
        this.onSave();
      }
    },
    async onSave() {
      if (!this.isEdit && !this.isCopy) {
        this.form.countryId = this.selectedCountry.id;
      }
      this.allCitiesSelected ? (this.form.cityId = null) : (this.form.cityId = this.selectedCity);
      this.form.categoryIds = this.oobleeCategoriesSelected.map(item => item.id);
      this.form.shopIds = this.selectedShops.map(item => item.id);
      this.form.productIds = this.selectedProducts.map(item => item.id);
      this.form.ord = +this.form.ord;
      this.form.type = this.selectedType?.id;
      const category = { ...this.form };
      const categoryId = this.form.id;

      const request = this.isEdit
        ? MFCategoriesApiService.editMFCategory(categoryId, category)
        : MFCategoriesApiService.createMFCategory(category);

      await request.then(response => {
        this.previousState();
        this.setForm();
        /*    this.$emit("save", {
          formData: category,
          response
        });*/
        console.log("categories response =>", response);
        this.$bvToast.toast(this.$i18n.t("common.savedSuccessfully"), {
          title: this.$i18n.t("menu.mainFeedCategories"),
          solid: true,
          variant: "success",
          autoHideDelay: 1000
        });
        /*
        setTimeout(() => {
          this.previousState();
        }, 1500);*/
      });
    }
  }
};
